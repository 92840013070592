.admin-stat{
    display: grid;
    width: 80%;
    box-sizing: border-box;
    padding-right: 10px;
    height: 100%;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(12,1fr);
    row-gap: 20px;
    column-gap: 20px;
}
.admin-stat-table{
    grid-column: 1/5;
    grid-row: 2/11;
    background-color: #4DAFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
    overflow: auto;
}
.ad-stat-table{
    height: auto;
}
.stat-tr-th,.stat-tr-td{
    width: auto;
    height: 45px;
}


.ad-stat-thead{
    height: 45px;
    width: 100%;
}
.ad-stat-tbody td{
    height: 35px;
    width: auto
}
.ad-stat-tbody tr:hover{
    background-color: #0085CE;
    cursor: pointer;
}
.ad-stat-tbody tr:active{
    transform: scale(0.98);
    transition: 0.1s;
}
.ad-stat-tbody tr:not(hover){
    transform: scale(1);
    transition: 0.2s;
}
.stat-tr-td{
    width: auto;
    max-height: 30px;
    height: 30px;
    text-align: center;
    margin-top: 5px;
    border: 2px solid #FFF;
}
.stat-table-data{
    overflow: auto;
}
.ad-stat-select input, #ad-select, option {
    width: 93%;
    height: 40px;
    padding: 0px 10px;
    border-style: none;
    text-align: center;
    border-radius: 10px;
    background-color: #9CDCFF;
    outline: none
}

.admin-stat-table-title{
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.admin-stat-table-title-text{
    width: auto;
    height: 60%;
    padding: 0px 15px;
    border-radius: 10px;
    color: #FFF;
    display: flex;
    font-size: 1.3rem;
    background-color: #0085CE;
    justify-content: center;
    align-items: center;
}
.admin-stat-table-data{
    width: 98%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.admin-stat-table-data input{
    width: auto;
}
.admin-stat-table-date{
    height: 8%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.admin-stat-table-date input{
    width: 40%;
    height: 40px;
    padding: 0px 10px;
    border-style: none;
    border-radius: 10px;
    background-color: #9CDCFF;
    outline: none;
}
.admin-stat-table-date-input{
    width: 40%;
    height: 60%;
}
.admin-stat-table-date-send{
    width: 100%;
    height: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-stat-table-date-send button{
    width: auto;
    padding: 0px 20px;
    height: 75.5%;
    background-color: #0085CE;
    color: #FFF;
    font-size: 1rem;
    border-radius: 10px;
    border-style: none;
}


.admin-stat-table-date-check{
    width: 90%;
    height: 8%;
    display: flex;
    color: #FFF;
    font-size: 1.2rem;
    justify-content: space-evenly;
    align-items: center;
}
.admin-stat-table-date-check input{
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
}
.ad-stat-select{
    width: 100%;
    height: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-stat-diagram{
    grid-column: 5/13;
    grid-row: 2/10;
    background-color:#4DAFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.admin-stat-diagram-title{
    width: 100%;
    height: 12%;
    display: flex;
}
.admin-stat-diagram-title-container-1{
    width: 25%;
    height: 100%;
    background-color: #4DAFE4;
    display: flex;
    align-items: center;
    border-radius: 10px;
}
.admin-stat-diagram-title-container-2{
    width: 53%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin-stat-diagram-title-container-2 .div-loading{
    width:40%
}
.admin-stat-diagram-title-text{
    width:auto;
    height: 60%;
    padding:0px 10px;
    background-color: #0085CE;
    border-radius: 10px;
    margin-left: 20px;
    font-size: 1.2rem;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-stat-diagram-title-info{
    width: auto;
    padding: 0px 10px;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 1.2rem;
    border-bottom: 2px solid #FFF;
}
.admin-stat-diagram-data{
    width: 80%;
    height: 70%;
}
.admin-stat-diagram-btn{
    height: 18%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-stat-diagram-btn button{
    width: 240px;
    height: 40px;
    border-radius: 10px;
    background-color: #0085CE;
    outline: none;
    border-style: none;
    color: #FFF;
    font-size: 1.2rem;
}
















.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input { 
opacity: 0;
width: 0;
height: 0;
}

.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #0085CE;
-webkit-transition: .4s;
transition: .4s;
}

.slider:before {
position: absolute;
content: "";
height: 26px;
width: 26px;
left: 4px;
bottom: 4px;
background-color: white;
-webkit-transition: .4s;
transition: .4s;
}

input:checked + .slider {
background-color: #0085CE;
}

input:focus + .slider {
box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
-webkit-transform: translateX(26px);
-ms-transform: translateX(26px);
transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
border-radius: 34px;
}

.slider.round:before {
border-radius: 50%;
}

@media screen and (max-width:1100px){
    .admin-page-content{
        height: auto;
    }
    .admin-stat{
        width: 98%;
    } 
    .admin-stat-diagram-data{
        width: 90%;
    }
}
@media screen and (max-width:800px){
    .admin-stat{
        height: 130%;
    }
    .admin-stat-table{
        grid-column: 3/11;
        grid-row: 1/8;
    }
    .admin-stat-diagram{
        grid-column: 1/13;
        grid-row: 8/13;
    }
    .admin-stat-diagram{
        height: 97%;
    }
}
@media screen and (max-width:500px){
    .admin-stat-table{
        grid-column: 1/13;
        grid-row: 1/8;
    }
    .admin-stat-diagram{
        grid-column: 1/13;
        grid-row: 8/13;
        height: 90%;
    }
    .admin-stat-diagram-title-info{
    font-size: 1.2rem;
    }
    .admin-stat-diagram-title-container-2{
        justify-content: flex-end;
        margin-right: 20px;
    }
    .admin-stat-diagram-title{
        height: 20%;
    }
    .admin-stat-diagram-title-text{
        margin: 0;
        height: 80%;
    }
    .admin-stat-diagram-title{
        flex-direction: column;
        height: 20%;
    }
    .admin-stat-diagram-title-container-1{
        width: 100%;
        height: 50%;
        justify-content: center;
    }
    .admin-stat-diagram-title-container-2{
        width: 100%;
        height: 50%;
        justify-content: flex-start;
        padding-left: 20px;
        font-size: 1.5rem;
    }
    .admin-stat-diagram-data{
        width: 98%;
        height:80%;
        display: flex;
        justify-content: center;
        align-items:stretch;
    }
    #ad-select{
        height: 30px;
    }
    .admin-stat-table-date input{
        height: 30px;
        width: 38%;
    }
}