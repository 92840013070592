.news-field{
    grid-column: 12 span;
    grid-row: 4 span;
    border-radius: 10px;
    max-height: 255px;
    background-color: #A3DDF3;
    display: flex;
    justify-content: space-between;
    border: 3px solid #A3DDF3;
}
.news-field-img{
    width: 25%;
    max-width:25%;
    box-sizing: border-box;
    height: 100%;
    border-radius: 10px;
    display: flex;
}
.news-field-img img{
    border-radius: 10px;
    height: 100%;
    width: auto;
}
.news-field-text-btn{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.news-field-text{
    box-sizing: border-box;
    height: 70%;
    padding:20px 30px 0px 0px;
    text-align: justify;
    line-height: 1.5rem;
    overflow: hidden;
    text-indent: 25px;
}

.news-field-btn{
    width: 100%;
    height:30%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.news-field-btn button{
    width: auto;
    height: 50%;
    padding: 0px 20px;
    border-radius: 10px;
    background-color: #4DAFE4;
    border-style: none;
    color: #FFF;
    font-size: 1.2rem;
}
#btn-delete{
    width: auto;
    height: 50%;
    padding: 0px 20px;
    border-radius: 10px;
    background-color: #eb6d6d;
    border-style: none;
    color: #FFF;
    font-size: 1.2rem;
}
@media screen and (max-width:1100px){
    .news-field-text-btn{
        width: 60%;
    }   
}
@media screen and (max-width:800px){
    .news-field-text-btn{
        width: 50%;
    }   
}
@media screen and (max-width:600px){
    .news-field{
        grid-row: 3 span;
    }
    .news-field-btn button{
        padding: 0px 10px;
        font-size: 0.8rem;
    }
    #btn-delete{
        padding: 0px 10px;
        font-size: 0.8rem; 
    }
    .news-field-text{
        font-size: 0.8rem;
    }
}
@media screen and (max-width:500px){
    .news-field{
        flex-direction: column;
        align-items: center;
        grid-row: 4 span;
    }
    .news-field-img{
        width: 100%;
        height: 50%;
        justify-content: center;
    }
    .news-field-text{
        padding: 0px;
    }
    .news-field-text-btn{
        width: 80%;
        height: 50%;
    }
    #btn-delete, .news-field-btn button{
        width: 40%;
        height: 70%;
    }
    .add-news{
        justify-content: center;
    }
}