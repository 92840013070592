.test-form{
    grid-row: 6 span;
    grid-column: 2 span;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.test-img{
    width: 100%;
    max-height: 45%;
    display: flex;
    justify-content: center;
}
.test-form img{
    width: 180px;
    height: auto;
}
.test-text{
    color: #000;
    padding-top: 20px;
    width: 90%;
    height: 45%;
    text-align: center;
    font-size: 1.1rem;
}
.test-button{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.test-button button{
    width: 80%;
    height: 80%;
    background-color: #5EBADC;
    border-radius: 7px;
    font-size: 1.1rem;
    color: #fff;
    border-style: none;
}
@media screen and (min-width:1500px) {

    
}
@media screen and (max-width:1500px) {
    .test-form img{
        width: 140px;
        height: initial;
    }
    .test-img{
        width: 100%;
        height: 40%;
    }
    .test-text{
        font-size: 1rem;
        height: 40%;
    }
    .test-button{
        height: 15%;
    }
    .test-button button{
        font-size: 0.9rem;
        height: 55%;
    }
}
@media screen and (max-width:950px) {
    .test-button button{
        font-size: 0.7rem;
        height: 50%;
    }
    .test-form img{
        width: 140px;
        height: initial;
    }
}
@media screen and (max-width:850px) {
    .test-text{
        font-size: 0.7rem;
        height: 30%;
    }
    .test-img{
        height: 40%;
    }
    .test-form img{
        width: 120px;
        height: 130px;
    }
}
@media screen and (max-width:800px) {
    .test-form{
        display: flex;
        grid-row: 4 span;
        grid-column: 4 span;
        width: 90%;
        height: 100%;
    }
    .test-text{
        padding: 0;
        height: 30%;
    }
    .test-img{
        height: 60%;
    }
    .test-button button{
        font-size: 0.9rem;
        height: 50%;
        width: 100%;
    }
    #test-1{grid-column: 2/6;grid-row: 2/7;}
    #test-2{grid-column: 6/10;grid-row: 2/7;}
    #test-3{grid-column: 10/14;grid-row: 2/7;}
    #test-4{grid-column: 4/8;grid-row: 7/12;}
    #test-5{grid-column: 9/13;grid-row: 7/12;}
}
@media screen and (max-width:500px) {
    .test-form{
        width: 90%;
    }
    .test-text{padding-top: 20px;}
    .test-form img{
        width: 130px;
        height: 130px;
    }
    .test-button{height: 24%;}
    #test-1{grid-column: 3/9;grid-row: 2/6;}
    #test-2{grid-column: 8/14;grid-row: 2/6;}
    #test-3{grid-column: 3/9;grid-row: 6/10;}
    #test-4{grid-column: 8/14;grid-row: 6/10;}
    #test-5{grid-column: 5/12;grid-row: 10/14;padding-top: 10px;}
}

@media screen and (max-width:360px) {
    #test-1{grid-column: 2/8}
    #test-3{grid-column: 2/8}
    #test-2{grid-column: 9/15}
    #test-4{grid-column: 9/15}
    .test-form img{
        width: 100px;
        height: 110px;
    }
    .test-button button{
        font-size: 0.8rem;
    }
}