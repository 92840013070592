tbody::-webkit-scrollbar {
    width: 10px;
}
tbody::-webkit-scrollbar-thumb {
    background-color: #A3DDF3;    /* цвет плашки */
    border-radius: 20px;       /* закругления плашки */
}


.user-research{
    display: grid;
    width: 80%;
    padding-right: 10px;
    box-sizing: border-box;
    height: 100%;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(12,1fr);
    row-gap: 10px;
    column-gap: 10px;
}
.input-file{
   grid-column: 3/11;
   grid-row: 1/4;
   background-color:#4DAFE4;
   border-radius: 10px;
   display: flex;
   flex-direction: column   ;
   justify-content: center;
   align-items: center;
}
#file{
    display: none;
}

.edit-div{
    position: absolute;
    top: 0;
    left: 0;
}

.input-file-title, .input-file-field, .input-file-btn{
    height: 33%;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: #FFF;
    font-size: 1.3rem;
}
.input-file-title-text, .input-file-field label, .input-file-btn button{
    width: auto;
    height: 50%;
    display: flex;
    align-items: center;
    border-style: none;
    border-radius: 10px;
    text-align: center;
    padding: 0px 20px;
}
.input-file-field label, .input-file-btn button{
    background-color: #0085CE;
    color: #FFF;
    font-size: 1.3rem;
}
.input-file-field label:active{
    transform: scale(0.97);
    transition: 0.2s;
}


.output-file{
    grid-column: 4/10;
    grid-row:4/13;
    height: 100%;
    width: auto;
    background-color: #4DAFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.output-file-title{
    height: 10%;
    width: 100%;
    display: flex;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    color:#FFF;
}
.output-file-field{
    max-height: 85%;
    width: 100%;
    display: flex;
    justify-content: center;
}
.output-file-field img{
    height: 100%;
    width: auto;
    max-height:100%;
}

@media screen and (max-width:1100px){
    .user-research{
        width: 98%;
    }
    .research-table-data table{
        overflow: scroll;
    }
    .input-file{
        grid-column: 4/10;
    }
    .output-file{
        grid-column: 3/11;
    }
}
@media screen and (max-width:800px){
    .output-file{
        grid-column: 1/13;
    }
    .input-file{
        grid-column: 3/11;
    }
}
@media screen and (max-width:550px) {
    .output-file-field img{
        width: 300px;
        height: auto;
        max-height: 350px;
        max-width: 98%;
    }
}
