.contact-form{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    grid-row: 5/10;
}
.contact-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
}
.contact-title{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
}
.contact-text{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    height: 60%;
}
#contact-1{
    grid-column:2/6 ;
    grid-row: 5/14;
}
#contact-2{
    grid-column:6/10 ;
    grid-row: 5/14;
}
#contact-3{
    grid-column:10/14 ;
    grid-row: 5/14;
}
@media screen and (max-width:1000px) {
    #contact-1{
        grid-column:1/5 ;
        grid-row: 5/10;
    }
    #contact-2{
        grid-column:6/10 ;
        grid-row: 5/10;
    }
    #contact-3{
        grid-column:11/15 ;
        grid-row: 5/10;
    }
    .contact-icon{height: 40%;}
    .contact-title{height: 20%;}
    .contact-text{height: 40%;}
}
@media screen and (max-width:800px) {
    .contact-text{
        font-size: 0.9rem;
    }
    .vector-info{font-size: 0.9rem;}
    .ftizis-info{font-size: 0.9rem;}
}
@media screen and (max-width:500px) {
    #contact-1{
        grid-column: 1/15;
        grid-row: 4/7;
    }
    #contact-2{
        grid-column: 1/15;    
        grid-row: 7/10;
    }
    #contact-3{
        grid-column: 1/15;
        grid-row: 10/13;
    }
    .contact-text{font-size: 0.8rem;}
    .contact-icon svg{
        font-size: 30px;
    }
}