.prize{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.prize-img{
    width: 40%;
    display: flex;
}
.prize-img img{
    width: 180px;
    height: auto;
}
.prize-text{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: justify;
    color: #4D4D4D;
}
.text-end{
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
}
#prize-1{
    grid-column: 1/15;
    justify-content: flex-start;
    grid-row: 3/7;
}
#prize-2{
    grid-column: 1/15;
    flex-direction: row-reverse;
    justify-content: space-between;
    grid-row: 6/10;
}
#prize-2 .prize-img{
    justify-content: flex-end;
}



@media screen and (max-width: 800px){
    #prize-2{
        grid-column: 1/15;
        grid-row: 7/11;
    }
}
@media screen and (max-width: 500px){
    #prize-1 img{width: 120px;
    height: 200px;}
    #prize-2 img{width: 120px;height: 230px;}
}
@media screen and (max-width: 400px){
    .prize-text{font-size: 0.9rem;}
}