.admin-news{
    display: grid;
    width: 80%;
    max-height: 80%;
    box-sizing: border-box;
    padding-right: 10px;
    padding-bottom: 20px;
    max-height: 100%;
    grid-template-columns: repeat(12,1fr);
    row-gap: 20px;
    column-gap: 20px;
}
.add-news{
    grid-column: 1/13;
    grid-row: 1/2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.add-news button{
    width: auto;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #4DAFE4;
    border-style: none;
    color: #FFF;
    font-size: 1.2rem;
}
.news-list{
    grid-column: 1/13;
    grid-row: 2/13;
}

@media screen and (max-width:1100px){
    .admin-news{
        width: 98%;
    }   
}