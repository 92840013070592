.abouttext-text{
    padding-top: 20px;
    display: flex;
    height: auto;
    width: 60%;
    text-indent: 40px;
}

@media screen and (max-width:1500px){
.abouttext-text{
    width: 70%;
}  
}
@media screen and (max-width:1000px){
    .abouttext-text{
        width: 90%;
        font-size: 1rem;
    } 
}