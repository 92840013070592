.admin-main{
    display: grid;
    top: 10%;
    width: 80%;
    box-sizing: border-box;
    padding-right: 10px;
    min-height: 100%;
    height: auto;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(12,1fr);
    row-gap: 20px;
    column-gap: 20px;
}
.main-user-table{
    grid-column: 1/13;
    grid-row: 1/7;
    background-color:#4DAFE4;
    color: #FFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.admin-table{
    height: auto;
}
.main-user-diagramm{
    grid-column: 1/13;
    grid-row: 7/13;
    background-color:#4DAFE4;
    color: #FFF;
    border-radius: 10px;
}
.main-user-table-title{
    height: 15%;
    width:100%;
    display: flex;
    align-items: center;
}
.main-user-table-title-text{
    padding: 10px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height:auto;
    color: #fff;
    border-radius: 10px;
    background-color: #0085CE;
}
.main-user-table-data{
    width: 96%;
    height: 78%;
}
.main-user-table-data{
    grid-column: 1/13;
    grid-row: 1/7;
    justify-content: center;
    align-items: center;
    background-color: #4DAFE4;
    border-radius: 10px;
    width: 97%;
    color: #FFF;
    height: 95%;
    overflow: auto;
}
.ad-stat-tr{
    border-bottom:3px solid #FFF;box-sizing: border-box;
}

.ad-th,.ad-td{
    width: auto;
    height: 45px;
    }

.ad-td{
    width: auto;
    height: 30px;
    text-align: center;
    margin-top: 5px;
    border: 2px solid #FFF;

}
.ad-td a{
    width: 100% !important;
    height: 100% !important;
    text-decoration: none;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ad-tbody tr:hover{
    background-color: #0085CE;
    cursor: pointer;
}
.ad-tbody tr:active{
    transform: scale(0.99);
    transition: 0.1s;
}
.ad-tbody tr:not(hover){
    transform: scale(1);
    transition: 0.2s;
}


#admin-thead{
    height: 45px;
}

.admin-stat-info-content{
    background-color: #4DAFE4;
    grid-column: 1/13;
    grid-row: 7/13;
    height: 97.5%;
    border-radius: 10px;
}
.admin-stat-info-content-title{
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
}
.admin-stat-info-content .block-name{height: 15%;}
.admin-stat-info-data{
    grid-column: 12/20;
    display: flex;
    height: 80%;
    flex-direction: column;
    justify-content: space-around;
}
.admin-stat-info{
    width: 100%;
    height: 80%;
    display: grid;
    grid-template-columns: repeat(20,1fr);
    grid-template-rows: repeat(10,1fr);
    column-gap: 10px;
}
.admin-stat-info-content-title-text{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    width: auto;
    height: auto;
    width: auto;
    padding: 10px;
    background-color: #0085CE;
    border-radius: 10px;
    color: #FFF;
}
.admin-stat-info-diagram{
    grid-column: 1/11;
    height: 90%;
    padding-left: 20px;
    width: 92%;
}

@media screen and (max-width:1100px){
    .admin-main{
        width: 98%;
    }
    .admin-stat-info-diagram{
        grid-column: 1/12;
    }
}
@media screen and (max-width:800px){
    .main-user-table-data table th,.main-user-table-data table td       {
        min-width: 120px
    }

    .admin-stat-info-diagram{
        grid-column: 1/21;
        grid-row: 2/9;
        height: 100%;
    }
    .admin-stat-info-content{
        grid-template-rows: repeat(12,1fr);
    }
    .admin-stat-info-data{
        grid-column: 2/20;
        grid-row: 9/13;
    }
    .admin-main{
        height: 120%;
    }
    .main-user-table{
        grid-row: 1/5;
    }
    .admin-stat-info-content{
        grid-row:5/13;
    }
    .admin-stat-info-content-title{
        height: 100%;
    }
    
}
@media screen and (max-width:500px){
    .admin-stat-info-content-title-text{
        margin-left: 5px;
        height: 20%;
    }
    .main-user-table-title-text{
        height: 30%;
        margin-left: 5px;
    }
    .admin-stat-info-diagram{
        grid-row: 1/6;
        display: flex;
        padding-left: 5px;
        width: 98%;
    }
    .admin-stat-info-data{
        grid-row: 6/11;
        height: 98%;
        font-size: 0.8em;
    }
    .admin-stat-info-content .block-name{
        height: 8%;
    }
}



