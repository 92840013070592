.about-page{
    background-image: url('../images/top-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}
.about-page .main-header{
    height: 40%;
}
.about-page .main-header-nav{
   height:auto;
}
.about-page .main-header-link{
    padding-top: 103px;
}

.about-title{
    width: 100%;
    margin: 60px 0px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5DADD9;
    font-weight: 300;
    font-size: 2.5rem;
}
.about-content{
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.about-content-title{
    color: #5DADD9;
    height: auto    ;
    padding-bottom: 40px;
    font-weight: 300;
    font-size: 1.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
}.about-content-text{
    display: flex;
    color: #4d4d4d;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
}
.example-container{
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.example-img{
    display: flex;
    width: 50%;
    padding: 10px 0px;
    justify-content: space-around;
    align-items: center;
}
.example-text{
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 80%;
    text-align: center;
}
.example-img img{
    width: 400px;
    height: 400px;}
.example-imag{
    height: 70%;
    width: auto;
    max-width: 90%;
    padding: 30px 0px;
}
.example-imag img{
    max-height: 540px;
}
.tech-text{
    width: 100%;
    height: auto;
    padding: 30px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;font-size: 1.3rem;
}
.imag-example-text{
    width: 90%;
    display: flex;
    justify-content: center;
    text-align: center;
}
.about-footer{
    width: 100%;
    height:120px;
    background-color: #4d4d4d;
    display: flex;
    justify-content: center;
    align-items: center;
}
.about-social{
    display: flex;
    width: 20%;
    height: auto;
    justify-content: space-evenly;
}
.about-social .soc-img{
    padding: 0;
}
.back-to-top {
    position: fixed;
    z-index: 2;
    bottom: 50vh;
    left: 20px;
    width: 50px;
    height: 50px;
    background-color: #A3DDF3;
    color: white;
    cursor: pointer;
    border-radius: 100px;
    display: flex;
}
.about-ftizis-info, .about-vector-info{
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    width: 35%;
}
.about-footer-content{
    display: flex;
    height: 100%;
    width: 75%;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
}
.about-footer a{
    display: flex;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width:1500px){
    .example-img img{
        width: 300px;
        height: 300px;
    }
}
@media screen and (max-width:1000px){
    .example-img{
        width: 80%;
    }
    .imag-example-text{
        font-size: 1rem;
    }
    .example-text{
        font-size: 1rem;
    }
    .example-img img{
        width: 150px;
        height: 150px;
    }
    .about-footer-content .social{
        width: 30%;
    }
    .about-footer-content{
        width: 100%;
    }
}
@media screen and (max-width:500px){
    .about-footer{
        height: 200px;
    }
    .about-footer-content{
        height: 70%;
        flex-direction: column;
        justify-content:space-between;
    }
    .about-ftizis-info, .about-vector-info{
        height: auto;
        width: 70%;
    }
    .about-social{
        width: 30%;
    }
}