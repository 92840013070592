.admin-news-edit{
    max-width: 100%;
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
}
.quill{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;}
.ql-editor img{
    width: 50%;
    height: auto;
}
.ql-toolbar.ql-snow{
    height: auto;
    width: 100%;
    display: flex;
}.ql-container.ql-snow{
    width: 100%;
}
.ql-picker-options{
    height: auto;
    width: auto;
}
.ql-snow.ql-toolbar button{
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ql-image{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}
.ql-toolbar.ql-snow .ql-formats{
    display: flex;
    align-items: center;
}
@media screen and (max-width:1100px){
    .admin-news-edit{
        width: 100%;
    }   
}