.new-content{
    background-image: url('../images/top-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: auto;
    min-height: 40%;
}

.new-content .news-page-header{
    height: 20%;
    padding-bottom: 85px;
}
.new-content .main-header-nav{
    height: 30%;
    min-height: 25%;
}
.new-content .main-header-link{
    padding-top: 116px;
}
.new-cont{
    width: 100%;
    height: 60%;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.new-cont-content{
    width: 70%;
    height: auto;
    min-height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.new-cont-title{
    width: 80%;
    display: flex;
    padding: 0px 0px 40px 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 30px;
    font-size: 1.4rem;
    color: #4facdd;
}
.new-cont-text{
    width: 100%;
    line-height: 30px;
    text-align: justify;
    font-size: 1.2rem;
    text-indent: 40px;
    color: rgb(83, 83, 83);
}
.new-video{
    width: 60%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-cont-article{
    width: 100%;
    line-height: 30px;
    text-align: justify;
    font-size: 1.2rem;
    font-weight: 600;
    box-sizing: border-box;
    background-color: #d3d3d3;
    padding: 10px 20px;
    text-indent: 40px;
    color: black;
}
.new-cont-subtitle{
    width: 100%;
    line-height: 30px;
    text-align: justify;
    font-size: 1.5rem;
    text-indent: 40px;
    padding: 10px 0px;
    color: black;
}
.new-cont-img-1{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px 0px;
}
.new-cont-img-1 img{
    width: 400px;
    height: auto;
}
.new-cont-img-2 img{
    width: 600px;
}
.new-cont-img-2{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px ;
}
#img-3{
    width: 70%;
    height: auto;
    border: 15px solid gray;
}
#img-4{
    width: 60%;
    height: auto;
}

.new-footer{
    width: 100%;
    height:100px;
    background-color: #4d4d4d;
    display: flex;
    justify-content: center;
    align-items: center;
}
.new-social{
    display: flex;
    width: 20%;
    height: auto;
    justify-content: space-evenly;
}
.new-social .soc-img{
    padding: 0;
}
.back-to-top {
    position: fixed;
    z-index: 2;
    bottom: 50vh;
    left: 20px;
    width: 50px;
    height: 50px;
    background-color: #A3DDF3;
    color: white;
    cursor: pointer;
    border-radius: 100px;
    display: flex;
}
.new-ftizis-info, .new-vector-info{
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    width: 35%;
}
.new-footer-content{
    display: flex;
    height: 100%;
    width: 75%;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
}
.new-footer a{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:1024px) {
    .new-cont-content{
        width: 85%;
    }
    .new-cont-title{
        width: 95%;
        font-size: 1.2rem;
    }
    .new-footer-content .social{
        width: 30%;
    }
    .new-footer-content{
        width: 100%;
    }
}
@media screen and (max-width:800px) {
    .new-cont-img-1 img{
        width: 300px;
    }
    .new-cont-img-2 img{
        width:350px;
    }
    #img-3,#img-4{
        width: 90%;
    }
    .new-video{
        width: 90%;
    }
}
@media screen and (max-width:500px){
    .new-footer{
        height: 200px;
    }

    #img-3,#img-4{
        width: 100%;
    }
    .new-footer-content{
        height: 70%;
        flex-direction: column;
        justify-content:space-between;
    }
    .new-ftizis-info, .new-vector-info{
        height: auto;
        width: 70%;
    }
    .new-social{
        width: 30%;
    }
}