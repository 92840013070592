.partner-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #A3DDF3;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 2px black;
    grid-row: 3/8;
}
.partner-form a{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}
.partner-img{
    width: 100%;
    height: 45%;
    display: flex;
    justify-content: center;
}
.partner-form img{
    width: 100%;
    height: auto;
}
.partner-text{
    color: #333333;
    padding-top: 20px;
    width: 90%;
    height: 35%;
    text-align: center;
    font-size: 1.1rem;
}
#partner-1{
    grid-column: 2/4;
    grid-row: 3/9;}
#partner-1 img{
    width: 96%;
    height: auto;}
#partner-2{
    grid-column: 4/6;
    display: flex;
    align-items: center;
    grid-row: 3/9;}
#partner-2 img{
    width: 100%;
    border-radius: 10px 10px 0px 0px;        
    height: auto;}
#partner-3{
    grid-column: 6/8;
    grid-row: 3/9;}
#partner-4{
    grid-column: 8/10;
    grid-row: 3/9;}
#partner-4 img{
    width: auto;
    height: 96%;
}
#partner-4 .partner-img{
    align-items: center;
    width: 100%;
    height: 45%;
}
#partner-5{
    grid-column: 10/12;
    grid-row: 3/9;}
#partner-5 img{
    width: 100%;
    border-radius: 10px 10px 0px 0px;        
    height: auto;}
.partner-form:hover{
    transform: scale(1.05);
    transition: 0.3s;
}
.partner-form:not(hover){
    transform: scale(1);
    transition: 0.3s;
}
.partner-form:active{
    transform: scale(0.97);
}





@media screen and (max-width:1440px) {
    .partner-text{
        font-size: 0.9rem;
    }
}
@media screen and (max-width:1200px) {
    #partner-1{grid-column:3/6;grid-row: 3/8;}
    #partner-1 img{width: 160px;height: auto;}
    #partner-2{grid-column:6/9;grid-row: 3/8;} 
    #partner-3{grid-column:9/12;grid-row: 3/8;} 
    #partner-4{grid-column:4/7;grid-row: 8/13;} 
    #partner-4 img{width: 130px;height: auto;}
    #partner-5{grid-column:8/11;grid-row: 8/13;}     
}
@media screen and (max-width:800px) {
    .partner-text{
        font-size: 0.9rem;
    }
    #partner-1{grid-column:2/6;grid-row: 3/8;}
    #partner-1 img{width: 160px;height: auto;}
    #partner-2{grid-column:6/10;grid-row: 3/8;} 
    #partner-3{grid-column:10/14;grid-row: 3/8;} 
    #partner-4{grid-column:4/8;grid-row: 8/13;} 
    #partner-4 img{width: 120px;height: auto;}
    #partner-5{grid-column:8/12;grid-row: 8/13;}     
}
@media screen and (max-width:500px) {
    .partner-text{font-size: 0.8rem;overflow: hidden;}
    #partner-1{grid-column:2/8;grid-row: 2/6;}
    #partner-1 img{width: 100%;height: auto;}
    #partner-2{grid-column:8/14;grid-row: 2/6;} 
    #partner-3{grid-column:2/8;grid-row: 6/10;} 
    #partner-4{grid-column:8/14;grid-row: 6/10} 
    #partner-4 img{width: 125px;height: auto;}
    #partner-5{grid-column:5/11;grid-row: 10/14;}     
}