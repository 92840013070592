.all-news-page{
    width: 100%;
    min-height: 100%;
    display: flex;
    height: auto;
    flex-direction: column;
}
.all-news-page .main-header-head{
    height: 14%;
}
.all-news-page .main-header-link{
    padding-top: 115px;
    height: 14%;
}
.all-news-page .main-header-nav{
    height: 30%;
}
.all-news-page .main-header-auth{
    align-items: flex-start;
    align-items: flex-start;
}
.news-page-header{
    background-image: url('../images/bg-news.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: auto;
    min-height: 40%;
}

.news-page-title{
    height: 20%;
    width: 100%;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#51ACDD;
    font-size: 2.5rem;
    font-weight: 400;
}
.news-page-content{
    background-image: linear-gradient(#FFFFFF, #7DB1C7 70%);
    display: grid;
    height: 70%;
    padding: 40px 0px;
    box-sizing: border-box;
    grid-template-columns: repeat(14,1fr);
}
.news-page-content .news-content-cards{
    grid-column: 4/12;
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(8,1fr);
    grid-auto-flow: row;
    grid-gap: 50px 30px;
}
.news-page-content #newsid{
    grid-column: 4 span;
    grid-row: 9 span
}

.news-page-content .news-text{
    width: 80%;
    font-size: 1.2rem;
    text-align: center;
}
.news-page-content a{
    align-items: center;
}
.allnew-footer{
    width: 100%;
    height:80px;
    background-color: #4d4d4d;
    display: flex;
    justify-content: center;
    align-items: center;
}
.allnew-social{
    display: flex;
    width: 20%;
    height: auto;
    justify-content: space-evenly;
}
.allnew-social .soc-img{
    padding: 0;
}

@media screen and (max-width:1024px) {
    .news-page-content .news-content-cards{
        grid-column: 2/14;
    }
}
@media screen and (max-width:800px) {
    .news-page-content .news-content-cards{
        grid-column: 2/14;
    }
    .news-page-content #newsid{
        grid-column: 6 span;
    }
}
@media screen and (max-width:550px) {
    .news-page-content .news-content-cards{
        grid-column: 3/13;
    }
    .news-page-content #newsid{
        grid-column: 12 span;
    }
    .allnew-footer{
        height: 120px;
    }
    .allnew-footer-content{
        height: 70%;
        flex-direction: column;
        justify-content:space-between;
    }
    .allnew-ftizis-info, .allnew-vector-info{
        height: auto;
        justify-content: center;
        width: 70%;
    }
    .allnew-social{
        width: 30%;
    }
}
@media screen and (max-width:450px) {
    .news-page-content .news-content-cards{
        grid-column: 2/8;
    }
}
@media screen and (max-width:400px) {
    .news-page-content .news-content-cards{
        grid-column:2/14 !important;
    }
}