.navbar{
    width: 100%;
    height: 8%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    padding: 10px 26px 5px 5px;
}
.user-data{
    width: 30%;
    height: 100%;
    display: flex;
    background-color: #4DAFE4;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    border: 1px solid #1f93d1;
}
.data-city, .user-priority{
    width: 35%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 1.1rem;
}
.user-icon, .exit-icon{
    width: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.data, .city, .user, .priority{
    height: auto;
    text-align: center;
}
.exit-icon a{
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-icon .main-header-language{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.user-icon .lang-img{
    width: 50px;
    height: auto;
    margin: 0;

}
@media screen  and (max-width:1100px){
    .user-data{
        width: 70%;
    }
}
@media screen  and (max-width:500px){
    .user-data{
        width: 82%;
        font-size: 1rem;
    }
    .navbar{
        padding-right: 10px;
    }
    .data-city, .user-priority{
        font-size: 0.9rem;
    }
}